var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-1"},[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',{staticClass:"text-center"},[(_vm.carregamentoDadosApi)?_c('b-spinner',{staticClass:"text-primary",attrs:{"small":"","label":"Carregando"}}):_vm._e()],1),(!_vm.carregamentoDadosApi)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Nome Completo","label-for":"usuarioNome"}},[_c('validation-provider',{attrs:{"name":"usuarioNome","vid":"usuarioNome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"usuarioNome","name":"usuarioNome","state":errors.length > 0 ? false:null,"placeholder":"Nome e Sobrenome"},model:{value:(_vm.usuarioNome),callback:function ($$v) {_vm.usuarioNome=$$v},expression:"usuarioNome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Data Nascimento","label-for":"usuarioDtNascimento"}},[_c('validation-provider',{attrs:{"name":"usuarioDtNascimento","vid":"usuarioDtNascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"usuarioDtNascimento","config":_vm.configUsuarioDtNascimento,"state":errors.length > 0 ? false:null,"placeholder":"DD/MM/AAAA"},model:{value:(_vm.usuarioDtNascimento),callback:function ($$v) {_vm.usuarioDtNascimento=$$v},expression:"usuarioDtNascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"CPF","label-for":"usuarioCpf"}},[_c('validation-provider',{attrs:{"name":"usuarioCpf","vid":"usuarioCpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"form-control",attrs:{"id":"usuarioCpf","state":errors.length > 0 ? false:null,"mask":['###.###.###-##'],"placeholder":"000.000.000-00"},model:{value:(_vm.usuarioCpf),callback:function ($$v) {_vm.usuarioCpf=$$v},expression:"usuarioCpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"WhatsApp","label-for":"usuarioCelular"}},[_c('validation-provider',{attrs:{"name":"usuarioCelular","vid":"usuarioCelular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"form-control",attrs:{"id":"usuarioCelular","state":errors.length > 0 ? false:null,"mask":['(##) #####-####'],"placeholder":"(00) 00000-0000"},model:{value:(_vm.usuarioCelular),callback:function ($$v) {_vm.usuarioCelular=$$v},expression:"usuarioCelular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"usuarioEmail"}},[_c('validation-provider',{attrs:{"name":"usuarioEmail","vid":"usuarioEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"usuarioEmail","name":"usuarioEmail","state":errors.length > 0 ? false:null,"placeholder":"email@exemplo.com","autocomplete":"off"},model:{value:(_vm.usuarioEmail),callback:function ($$v) {_vm.usuarioEmail=$$v},expression:"usuarioEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":""},model:{value:(_vm.usuarioConfirmacaoEmail),callback:function ($$v) {_vm.usuarioConfirmacaoEmail=$$v},expression:"usuarioConfirmacaoEmail"}},[_vm._v(" Confirmar Email ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.respostaApi}},[_vm._v(" Salvar "),(_vm.respostaApi)?_c('b-spinner',{attrs:{"small":"","label":"Carregando"}}):_vm._e()],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }