<template>
  <b-card class="p-1">

    <b-card-header>
      <h3>Hits</h3>
    </b-card-header>

    <b-card-body>
      <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="historico"
      responsive
      :fields="tableColumns"
      primary-key="data"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Sem resultados"
      :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'usuarios-compositor-editar-hit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(statusAprovacaoHit)="data">
          <b-badge
            pill
            :variant="`light-${statusAprovacaoVariant(data.item.statusAprovacaoHit)}`"
            class="text-capitalize"
          >
            {{ statusAprovacaoLabel(data.item.statusAprovacaoHit) }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalHistorico"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="mudarPagina"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {

      historico: [],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'nomeComposicao', label: 'Composição', sortable: true },
        { key: 'statusAprovacaoHit', label: 'Status', sortable: true },
        { key: 'actions', label: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalHistorico: 0,
      perPageOptions: [10, 25, 50, 100],
      pesquisar: '',
      sortBy: 'data',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,

    }
  },
  props: {
    compositorId: Number,
  },
  created() {
    this.usuarioCompositorSaldoHistoricoLista()
  }, 
  methods: {

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMACOES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'warning'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado Geral'
      if (status === 'REPROVADO_INFORMACOES') return 'Reprovado Informações'
      if (status === 'REPROVADO_GUIA') return 'Reprovado Guia'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    // CARREGAR APROVAÇÕES

    usuarioCompositorSaldoHistoricoLista() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      // console.log( paginaAtual );

      useJwt.usuarioCompositorHitsLista({
        compositorId: this.compositorId,
        page: paginaAtual,
        size: this.perPage,
        sort: 'ASC',
      })
        .then(response => {
          this.historico = response.data.content
          this.totalHistorico = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

    // MUDAR PÁGINA

    mudarPagina() {
      this.usuarioCompositorSaldoHistoricoLista()
    },

    // MUDAR PÁGINA

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
