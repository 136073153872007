<template>
  <div>
    <b-table
    ref="refUserListTable"
    class="position-relative"
    :items="historico"
    responsive
    :fields="tableColumns"
    primary-key="data"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="Sem resultados"
    :sort-desc.sync="isSortDirDesc"
    >

    </b-table>
    <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalHistorico"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {

      historico: [],
      tableColumns: [
        { key: 'data', label: 'Data', sortable: true },
        { key: 'saldoAnterior', label: 'Saldo Anterior', sortable: true },
        { key: 'saldoAtual', label: 'Saldo Atual', sortable: true },
        { key: 'usuarioAlteracaoNome', label: 'Alterado por', sortable: true },
      ],
      perPage: 10,
      currentPage: 1,
      totalHistorico: 0,
      perPageOptions: [10, 25, 50, 100],
      pesquisar: '',
      sortBy: 'data',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,

    }
  },
  props: {
    compositorId: Number,
  },
  created() {
    this.usuarioCompositorSaldoHistoricoLista()
  },
  methods: {

    // CARREGAR APROVAÇÕES

    usuarioCompositorSaldoHistoricoLista() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      // console.log( paginaAtual );

      useJwt.usuarioCompositorSaldoHistoricoLista({
        usuarioId: this.compositorId,
        page: paginaAtual,
        size: this.perPage,
        sort: 'ASC',
      })
        .then(response => {
        //   console.log( response.data );

          this.historico = response.data.content
          this.totalHistorico = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

    // MUDAR PÁGINA

    mudarPagina() {
      this.usuarioCompositorSaldoHistoricoLista()
    },

    // MUDAR PÁGINA

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
