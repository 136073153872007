<template>
  <b-card class="p-1">

    <h6>Crédito Disponível</h6>
    <h1
      class="text-primary"
    >
      <b-icon
        font-size="15"
        icon="wallet2"
        color="#000"
      />
      {{ compositorSaldoAtual }}
    </h1>

    <!-- form -->
    <validation-observer
        #default="{invalid}"
        ref="compositorSaldoForms"
      >
        <p
          class="text-center"
        >
          <b-spinner
              v-if="carregamentoDadosApi"
              small
              label="Carregando"
              class="text-primary"
          />
        </p>
        <!-- Form -->
        <b-form
          v-if="!carregamentoDadosApi"
          class="mt-2"
          @submit.prevent="register"
        >

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Saldo -->
              <b-form-group
                label="Saldo"
                label-for="compositorSaldo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="compositorSaldo"
                  vid="compositorSaldo"
                >
                  <b-form-input
                    id="compositorSaldo"
                    v-model="compositorSaldo"
                    name="compositorSaldo"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Saldo -->

            </b-col>

          </b-row>


          <b-button
            variant="primary"
            type="submit"
            :disabled="invalid || respostaApi || compositorSaldo == compositorSaldoAtual"
          >
            Salvar
            <b-spinner
              v-if="respostaApi"
              small
              label="Carregando"
            />
          </b-button>

          <b-row class="mt-1">
            <b-col>
              <h4 class="mb-1 mt-1">Histórico de Saldo</h4>
              <compositor-saldo-historico :compositor-id="compositorId"/>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import {
  BSpinner, BFormFile, BAlert, BIconEnvelope, BCard, BMedia, BMediaAside, BMediaBody, BFormInvalidFeedback, IconsPlugin, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import useJwt from '@/auth/jwt/useJwt'

import DisableAutocomplete from 'vue-disable-autocomplete'

import { TheMask } from 'vue-the-mask'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

import CompositorSaldoHistorico from './CompositorSaldoHistorico.vue'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      compositorSaldo: {
        required: 'O saldo é obrigatório.',
      },
    },
  },
})


export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // autocomplete off
    DisableAutocomplete,
    TheMask,
    CompositorSaldoHistorico,
  },
  directives: {
    Ripple,
  },
  props: {
    compositorId: Number,
  },
  data() {
    return {
      required,
      compositorSaldo: null,
      compositorSaldoAtual: null,
      respostaApi: false,
      carregamentoDadosApi: false,
    }
  },
  mounted() {
    this.usuarioDados()
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    usuarioDados() {

      this.carregamentoDadosApi = true

      useJwt.usuarioCompositorId({
        id: this.compositorId
      })
      .then(response => {

        // console.log(response)

        const usuario = response.data

        this.compositorSaldo = usuario.saldo
        this.compositorSaldoAtual = usuario.saldo

        this.carregamentoDadosApi = false

       // alert(this.usuarioDtNascimento)
        
      })
      .catch(error => {
        //console.log( error.response );
        this.$swal({
          title: 'Erro!',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

      })
      // .finally(() => {
      //   this.carregamentoDadosApi = false
      // })

    },

    register() {
      this.$refs.compositorSaldoForms.validate().then(success => {
        if (success) {
          // console.log( this.usuarioCelular );return false;

          this.respostaApi = true

          useJwt.usuarioCompositorSaldoEditar({
            usuarioId: this.compositorId,
            saldo: this.compositorSaldo,
          })
            .then(response => {
              //console.log( response )

              this.$swal({
              title: 'Saldo Atualizado!',
              text: 'O saldo foi atualizado com sucesso.',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {

                this.$router.go()

              } else {

                this.$router.go()

              }
            })

            })
            .catch(error => {
              // console.log( error.response );

              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

              // this.$refs.registerForm.setErrors(error.response.data.error)
            })
            .finally(() => {
              this.respostaApi = false
            })
        }
      })
    },

  },
}
</script>
