<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- Geral Tab -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informações</span>
      </template>

      <compositor-informacoes 
        :compositor-id="compositorId"
      />
    </b-tab>
    <!--/ Geral Tab -->

    <!-- Geral Tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Saldo</span>
      </template>

      <compositor-saldo
        :compositor-id="compositorId"
      />
    </b-tab>
    <!--/ Geral Tab -->

    <!-- Alterar Senha Tab -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="MusicIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Hits</span>
      </template>

      <compositor-hits
        :compositor-id="compositorId"
      />
    </b-tab> 
    <!--/ Alterar Senha Tab -->
  </b-tabs>
</template>

<script>
import router from '@/router'
import { BTabs, BTab } from 'bootstrap-vue'
import CompositorInformacoes from './CompositorInformacoes.vue'
import CompositorSaldo from './CompositorSaldo.vue'
import CompositorHits from './CompositorHits.vue'

export default {
  components: {
    BTabs,
    BTab,
    CompositorInformacoes,
    CompositorSaldo,
    CompositorHits,
  },
  data() {
    return {
      compositorId: router.currentRoute.params.id
    }
  }

}
</script>
